"use client";

import { useEffect, useState } from "react";
import _e from "@/helpers/texts";
import LoadingLink from "../LoadingLink/LoadingLink";
import Image from "next/image";

export default function WAWidget({ whatsapp, phone }: {
  readonly whatsapp: Record<string,any>,
  readonly phone: Record<string,any>,
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ bottom, setBottom ] = useState<number>();

  let callMode = process?.env?.NEXT_PUBLIC_CALL_MODE ?? 'whatsapp';
  if(!['phone', 'whatsapp'].includes(callMode ?? '')) callMode = 'whatsapp';
  
  useEffect(() => {
    const wab = document?.querySelector('[data-wa-bottom]') as HTMLElement; // WA Bottom value

    const callback = () => {
      setBottom(parseInt(wab.getAttribute('data-wa-bottom') ?? '0') ?? 0);
    };

    if(wab) {
      callback();
    } else setBottom(0);
    
    setIsLoaded(true);

    const observer = new MutationObserver(callback);
    if(wab) observer.observe(wab as Node, { attributes: true, childList: false, subtree: false });

    return () => {
      if(wab) observer.disconnect();
    };
  }, []);
  
  if(isLoaded) return (
    <div
      className="fixed right-0 w-[80px] h-[80px] flex justify-end items-center z-[10000] transition-all"
      style={{ 'bottom': (bottom ?? 0) + 'px' }}
    >
      {
        callMode === 'phone' ? (
          <a href={phone?.url ?? 'tel:+902122367441'} target="_blank" title={_e('Doping Hafıza Çağrı Merkezi')} className="flex gap-2 items-center">
            <Image
              src="/assets/svg/callgreen.svg"
              alt={_e('Doping Hafıza Çağrı Merkezi')}
              width={66}
              height={70}
            />
          </a>
        ) : (
          <LoadingLink href={(whatsapp?.link ?? 'https://wa.me/905332362005') + '?text=' + (whatsapp?.text ?? _e('Merhaba, bilgi almak istiyorum.'))} target="_blank" rel="noopener noreferrer" title={whatsapp?.title ?? _e('Whatsapp ile Soru Sorun')} className="flex gap-2 items-center">
            <Image
              src="/assets/svg/whatsapp.svg"
              alt={whatsapp?.title ?? _e('Whatsapp ile Soru Sorun')}
              width={60}
              height={60}
            />
          </LoadingLink>
        )
      }
    </div>
  );

  return (<></>);
}
